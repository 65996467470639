<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
            <!-- 期间限定 -->
      <a style="display: block;" target="_blank" href="https://maidocoin.com/entrance/?lang=en">
        <el-image :src="qjxdImg">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
      </a>

      <!-- 积分 -->
      <a id="stepImgId" style="display: block;" target="_blank" href="https://tripellet.com/tcapp-download-b/kr">
        <el-image :src="stepImg">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
      </a>
      <!-- 设施一览表 -->
      <div style="background-color: #f3d2c3;">
        <el-image :src="modelcourse">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
        </el-image>
        <el-image :src="modelcourse2">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
        <el-image :src="placeholder_title">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
      </div>
      <!-- 使用方法 -->
      <!-- <div class="usage_box">
        <div class="title">A must-have E-ticket package for sightseeing in Kansai. Select popular facilities and restaurants in Kansai area. You can choose 3 or 6 of your favorite facilities within a week. Present the QR code on the E-ticket to easily enter the venue.</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">How to use</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
            ● The same facility can only be used once, and the same facility can not be re-entered.<br>
            ● Ticket validity period: <span>90 days</span> after purchase.<br>
            EX: Tickets purchased on <span>7/1</span> ⇒ <span>Ticket validity period:7/1~9/28.</span><br>
            ● Ticket validity period: After scanning the code at the first facility, it is valid for <span>7 days.</span><br>
            EX: If you start to use it on <span>7/1</span> ⇒ Validity period: <span>7/1~7/7.</span><br>
            ● Available facilities: You can use <span>3</span> or <span>6</span> facilities during the valid period.<br>
            ● Please note that when you purchase more than one ticket in a reservation, the starting date will apply to all tickets. 
            (<span>The validity period is 7 days</span>).<br>
            ● When the ticket has been used, the <span>7 day</span> validity period will not exceed the <span>90 day</span> validity period of the ticket itself.<br>
            EX: Ticket period: <span>7/1~9/28</span>, if the ticket starts on <span>9/27</span>, the valid time is: <span>9/27~9/28</span>.<br>
            ● Facilities that are unused cannot be refunded.<br>
            ● Please check the information about each facility, business hours, and public holidays in advance at the following links.
        </div>
      </div> -->
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>

        <div class="xiao_btn_img" v-if="item.iconImgBr">
          <a v-if="item.iconImgBr1" target="_blank" :href="item.iconVideoHref"><img :src="xiao_btn_img1" alt=""></a>
          <a v-if="item.iconImgBr2" href="#stepImgId"><img :src="xiao_btn_img2" alt=""></a>
          <a v-if="item.iconImgBr3" href="javascript:;"><img :src="xiao_btn_img3" alt=""></a>
        </div>
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='Notice'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='Redemption Location' && !contentItem.noColor">{{cItem}}<br></span>
                    <a class="dltAbq" :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'wordWrap': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='Official Website' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- notice特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfkansai/en/havefun_kansai_en.png'),
      qjxdImg: require('@/assets/images/hfkansai/en/qjxd_banner_en.png'),
      stepImg: require('@/assets/images/hfkansai/en/4step_en.png'),
      modelcourse: require('@/assets/images/hfkansai/en/havefun_modelcourse_1_en.jpg'),
      modelcourse2: require('@/assets/images/hfkansai/en/havefun_modelcourse_2_en.jpg'),
      placeholder_title: require('@/assets/images/hfkansai/en/placeholder_title_EN.png'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      xiao_btn_img1: require('@/assets/images/hfkansai/en/play_EN.png'),
      xiao_btn_img2: require('@/assets/images/hfkansai/jifen.png'),
      xiao_btn_img3: require('@/assets/images/hfkansai/en/qjxd2_EN.png'),
      dataList: [
        // {
        //   id: 1,
        //   img: require('@/assets/images/hfkansai/en/グループ 218.png'),
        //   title: ['ABENO HARUKAS Admission Ticket'],
        //   message: [
        //     {
        //       msgId: 1,
        //       title: 'Redemption Location',
        //       content: [{ cid: 1, text: ['1-1-43 Abenosuji, Abeno-ku, Osaka, Japan'] }, { cid: 2, text: ['(16 F of ABENO HARUKAS 300 Observatory)'] }]
        //     },
        //     {
        //       msgId: 2,
        //       title: 'Official Website',
        //       content: [{ cid: 1, text: ['https://www.abenoharukas-300.jp/en/observatory/'] }]
        //     },
        //     {
        //       msgId: 3,
        //       title: 'Notice',
        //       isDecimal: true, //是否启用ul的数字前缀
        //       content: [
        //         { cid: 1, text: ['Only use once, after the exit can not be re-admission'] },
        //         { cid: 2, text: ['Guest can enter the attraction by presenting the', 'E-ticket at 16FL of Abeno Harukas300 Observatory.'] }
        //       ]
        //     }]
        // },
        {
          id: 2,
          img: require('@/assets/images/hfkansai/kintetsu_en2.jpg'),
          title: ['Kintetsu Department Store ','Original Set Exchang Ticket'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['1-1-43 Abenosuji, Abeno-ku, Osaka city, Osaka, ','Wing Building 3.5th Floor Foreign Customers Salon'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://abenoharukas.d-kintetsu.co.jp/special/foreign/index.html'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['It can be exchanged for the Kintetsu Department Store Original Set. (Imabari Premium Towel & Kintetsu Department Store Mascot Folder Set)'] },
                { cid: 2, text: ['Please note that the set product cannot be returned or changed'] },
                { cid: 3, text: ['This is only applicable at Kintetsu Department Store Main Store ABENO HARUKAS.'] },
                { cid: 4, text: ['The picture is for reference only, the actual product please focus on the physical product.'] }
              ]
            }]
        },
        {
          id: 3,
          img: require('@/assets/images/hfkansai/en/グループ 217.png'),
          title: ['WONDER LOOP BUS 1DAY'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['1-16, Dotonbori, Chuo-ku, Osaka, Along the river B1 (Dotonbori Riverside Grill Wonder)'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://wonderloop.jp/wonder-loop/'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please buckle up for your safety.', 'Do not stand and move to change seat.'] },
                { cid: 2, text: ['There might be suspended service or changed', 'routes in the event of campaigns', '(traffic constraints or guidance).'] }
              ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfkansai/en/グループ 216.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/oXH-_2qjNlU',
          title: ['WONDER LOOP Cruise'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['1-16, Dotonbori, Chuo-ku, Osaka, Along the river B1 (Dotonbori Riverside Grill Wonder)'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://wondercruise.jp/doton/'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the operation schedule on the', 'Official Website.'] },
                { cid: 2, text: ['Sometimes the operation will stop due to', 'meteorological conditions such as strong wind', 'and rainfall.'] },
                { cid: 3, text: ['You may not be able to board the boat if it is full.'] },
                { cid: 4, text: ['The ride is only open to passengers with reservations on every Monday to Wednesday.'] }
              ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfkansai/en/グループ 223.png'),
          title: ['Osaka Gourmet Walk(2 Tickets)'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [
                { cid: 1, text: ['●1-16, Dotonbori, Chuo-ku, Osaka, Along the river B1 (Dotonbori Riverside Grill Wonder)'] },
                ]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['http://gourmet-walk.com/'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please present the ticket at the redemption counter to get 2 meal voucher.'] },
                { cid: 2, text: ['Please hand the whole tickets as attached together with stub when ordering at each location. '] },
                { cid: 3, text: ['Cannot substitute menu for another menu.'] },
                { cid: 4, text: ['No refund for unused tickets under any conditions.'] },
                { cid: 5, text: ['Please check the official website for the opening hours of the redemption counter in advance.'] }
                ]
            }
          ]
        },
        // -------------------
        {
          id: 6,
          img: require('@/assets/images/hfkansai/en/298_en.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/TGv70R5L37k',
          title: ['298 Yakinuku Coupon JPY1000'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '●298 Namba(Currently closed)',
                '1-7-9, Sennichimae 4F, Chuo-ku, Osaka-shi, Osaka',
                '●298 Shinsaibashi',
                '1-2-8 Shinsaibashisuji, Chuo Ward, Osaka City, Osaka Prefecture',
                '●298 Umeda store 3',
                '5-8 Doyama-cho, Kita-ku, Osaka-shi, Osaka ',
                '(North side of Izumi Square)',
                '●298Sannomiya<span style="color:#FBB03B;">(Kobe)</span>',
                'Ajibiru Sannomiya B1, 1-4-2 Kitanagasadori, Chuo-ku, Kobe',
                '●298 Fukushima',
                '6-1-39 Fukushima, Fukushima-ku, Osaka City, Osaka Prefecture'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['298 Namba'],noColor:true },
                { cid: 2, text: ['http://www.ajibil.com/shop/298N.html'] },
                { cid: 3, text: ['298 Shinsaibashi'],noColor:true },
                { cid: 4, text: ['https://298shinsaibashi.owst.jp/en/'] },
                { cid: 5, text: ['298 Umeda store 3'],noColor:true },
                { cid: 6, text: ['https://ajibiru-kashin-umeda.owst.jp/en/'] },
                { cid: 7, text: [`298 Sannomiya<span style="color:#FBB03B;">(Kobe) </span>`],noColor:true },
                { cid: 8, text: ['http://www.ajibil.com/shop/298S.html'] },
                { cid: 9, text: [`298 Fukushima`],noColor:true },
                { cid: 10, text: ['https://r.gnavi.co.jp/jm0fdd5d0000/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1,000 yen coupon.'] },
                { cid: 2, text: ['If there is a price difference, the difference needs to be paid on site.'] },
                { cid: 3, text: ['298 Sannomiya Branch is located in Kobe.'] },
                { cid: 4, text: ['The 1,000 yen coupon can be used at any of the following 5 stores - 298 Namba store, 298 Shinsaibashi store, 298 Umeda store 3, 298 Sannomiya store, and 298 Fukushima Store'] },
                { cid: 5, text: ['Please understand that there may be cases that the ticket cannot be used because the store is full.'] }
                ]
            }
          ]
        },
        // 
        {
          id: 7,
          img: require('@/assets/images/hfkansai/nonotori_EN.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/wg6_QwDLejA',
          title: ['Nonotori Coupon JPY1000'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '●野乃鳥なんば堂',
                'GEMSNanba, 8F, 3 Chome-7-19 Nanba, Chuo Ward, Osaka',
                '●KOBE YAKITORI STAND 野乃鳥<span style="color:#FBB03B;">(Kobe)</span>',
                'EKIZO KobeSannomiya, 1 Chome-1-1 Kitanagasadori, Chuo Ward, Kobe',
                '●野乃鳥 梅味堂',
                'Under JR Elevated bridge 34, 3 Chome−3−15, Nakazakinishi, Kita Ward,Osaka',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['野乃鳥なんば堂'],noColor:true },
                { cid: 2, text: ['https://nonotory.jp/stores/nambado/'] },
                { cid: 3, text: ['KOBE YAKITORI STAND 野乃鳥<span style="color:#FBB03B;">(Kobe)</span>'],noColor:true },
                { cid: 4, text: ['https://nonotory.jp/stores/kobe-yakitori-stand/'] },
                { cid: 5, text: ['野乃鳥 梅味堂'],noColor:true },
                { cid: 6, text: ['https://nonotory.jp/stores/umemido/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1,000 yen coupon.'] },
                { cid: 2, text: ['If there is a price difference, the difference needs to be paid on site.'] },
                { cid: 3, text: ['KOBE YAKITORI STAND Nonotori is located in Kobe.'] },
                { cid: 4, text: ['The 1,000 yen coupon can be used at any of the following 3 stores -Nonotor Nambado, KOBE YAKITORI STAND Nonotori, Nonotor umemido.'] },
                { cid: 5, text: ['Extra fee for side dishes will be charged while entering the restaurant.'] },
                { cid: 6, text: ['Please understand that there may be cases that the ticket cannot be used because the store is full.'] },
                { cid: 7, text: ['Present Travel Contents APP at checkout and enjoy 10% points reward. ','<br>Click here to download：<a style="color: rgb(33, 157, 204);border-bottom: 1px solid;" href="http://onelink.to/xyx76x">http://onelink.to/xyx76x</a>'] }
                ]
            }
          ]
        },
        {
          id: 46,
          img: require('@/assets/images/hfkansai/en/heniuunou_EN.png'),
          iconImgBr: true,
          // iconImgBr1: true,
          iconImgBr2: true,
          iconImgBr3: true,
          title: ['Wagyu beef Unou 1000 yen coupon ','<span>(Period limited:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [
                { cid: 1, text: ['Annex: 2-chome-15-22 TamadenakaNishinari Ward, Osaka '] },
                { cid: 2, text: ['Miyakojima Hondori store: 3-chome-27-7 MiyakojimahondoriMiyakojima Ward, Osaka '] },
                { cid: 3, text: ['Fukushima main store: 2-chome-7-7 FukushimaFukushima Ward, Osaka '] },
                ]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['Annex: No website'],noColor:true },
                { cid: 2, text: ['Miyakojima Hondori store:'],noColor:true },
                { cid: 3, text: ['https://www.hotpepper.jp/strJ000716500/'] },
                { cid: 4, text: ['https://instagram.com/unou_gram?igshid=YzAwZjE1ZTI0Zg=='] },
                { cid: 5, text: ['Fukushima main store:'],noColor:true },
                { cid: 6, text: ['https://unoufukushima.owst.jp/'] },
                { cid: 7, text: ['https://www.instagram.com/unou_7319/?hl=ja'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Price difference required if needed.'] },
                { cid: 2, text: ['Please check the official website in advance for business hours.'] },
                { cid: 3, text: ["We apologize for the possibility that the store may not be available when it is full."] },
                { cid: 4, text: ["Each coupon can be used once per store.",'<br>Example: Even if you have already used it at the annex, you can still use it at the Miyamotori store.'] },
                { cid: 5, text: ["Time limit is 90 minutes during peak hours."] },
              ]
            }
          ]
        },
        {
          id: 47,
          img: require('@/assets/images/hfkansai/en/Nanjamonja_EN.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['Nanjamonja 1000 yen coupon','<span>(Period limited:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [
                { cid: 1, text: ['NT Building 2F, 1-13-19 Higashi-Shinsaibashi, Chuo-ku, Osaka-shi, Osaka Prefecture'] },
                ]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://gurunavi.com/en/k342800/rst/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ["Enjoy Osaka's famous okonomiyaki with a 1,000 yen coupon."] },
                { cid: 2, text: ['If there is a price difference, the difference must be paid on site.'] },
                { cid: 3, text: ["Please check the official website in advance for business hours."] },
                { cid: 4, text: ["We apologize for the possibility that the store may not be available when it is full. "] },
              ]
            }
          ]
        },
        {
          id: 48,
          img: require('@/assets/images/hfkansai/en/95jiaoshi_EN.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          iconImgBr2: true,
          iconImgBr3: true,
          title: ['Osaka Sanshin Classroom One-Hour Experience (Advance reservation required) ','<span>(Period limited:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [
                { cid: 1, text: ['4-4-5 Tatsuminaka, Ikuno-ku, Osaka-shi, Osaka Prefecture'] },
                ]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://500en-sanshin-school.jimdosite.com/'] },
                { cid: 2, text: ['(Reservation Website)'],noColor:true },
                { cid: 3, text: ['https://airrsv.net/sansin/calendar'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ["Product Content: 1-hour experience fee."] },
                { cid: 2, text: ["Please check the official website in advance for business hours. "] },
              ]
            }
          ]
        },
        {
          id: 49,
          img: require('@/assets/images/hfkansai/en/96jas_EN.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['Religious corporation Kyuan-ji Zazen, Sutra calligraphy, or Buddha Painting Experience.(Advance reservation required)','<span>(Period limited:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [
                { cid: 1, text: ['697 Fushio-cho, Ikeda City, Osaka Prefecture'] },
                ]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://kyuanji.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ["Product Content: Zazen, Sutra calligraphy, or Buddha Painting Experience. Choose one of the three, including an admission fee of 300 Japanese Yen."] },
                { cid: 2, text: ["Please check the official website in advance for business hours. "] },
              ]
            }
          ]
        },
        {
          id: 50,
          img: require('@/assets/images/hfkansai/en/97rwgj_EN.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['Someya Takahisa Dyeing Experience with a 1000 Yen Discount Coupon (Advance reservation required).','<span>(Period limited:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [
                { cid: 1, text: ['6-17 Sakae-cho, Ikeda-shi, Osaka Prefecture'] },
                ]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://someyakikyu.com/'] },
                { cid: 2, text: ['(Reservation Website)'],noColor:true },
                { cid: 3, text: ['https://kikyu.urkt.in/ja/direct/offices/65/courses'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ["You can enjoy a 1,000 yen coupon."] },
                { cid: 2, text: ['Price difference fee required if needed.'] },
                { cid: 3, text: ["Please check the official website in advance for business hours. "] },
              ]
            }
          ]
        },
        {
          id: 51,
          img: require('@/assets/images/hfkansai/en/98bbdc_EN.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['Japan Shajindo Association Headquarters Dojo 1000 yen coupon(Advance reservation required)','<span>(Period limited:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [
                { cid: 1, text: ['1-1-27 Kitahorie, Nishi-ku, Osaka, Osaka Prefecture (3F)'] },
                ]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://nihontatedokyokai.com/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ["You can enjoy a 1,000 yen coupon."] },
                { cid: 2, text: ['Price difference fee required if needed.'] },
                { cid: 3, text: ["Please check the official website in advance for business hours. "] },
                { cid: 4, text: ["Reservation required."] },
              ]
            }
          ]
        },
        {
          id: 52,
          img: require('@/assets/images/hfkansai/en/Kawaii_EN.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['Kawaii Osaka KIMONO SHOP Kimono Purchase/Kimono Experience with a 1000 Yen Discount Coupon.','<span>(Period limited:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [
                { cid: 1, text: ['1-9 Souemonchō, Chuo Ward, Osaka, 542-0084'] },
                ]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://kawaii-osaka.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ["You can enjoy a 1,000 yen coupon.(Kimono purchase/Kimono experience)"] },
                { cid: 2, text: ['Price difference fee required if needed.'] },
                { cid: 3, text: ["Please check the official website in advance for business hours. "] },
                { cid: 4, text: ["Service may not be provided due to factors such as reservation status. It is recommended to make online reservations through the website."] },
              ]
            }
          ]
        },
        // 
        {
          id: 17,
          img: require('@/assets/images/hfkansai/en/SantaMaria_en.png'),
          title: ['Santa Maria Day Cruise ticket','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒552-0022 1-1-10, Kaigandori, Osaka Shi Minato Ku, Osaka Prefecture',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://suijo-bus.osaka/language/santamaria/'],}
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the operation schedule on the official website.'] },
                { cid: 2, text: ['Sometimes the operation will stop due to meteorological conditions such as strong wind and rainfall.'] },
                { cid: 3, text: ['You may not be able to board the boat if it is full.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 18,
          img: require('@/assets/images/hfkansai/en/Paragliding_en.png'),
          title: ['Paragliding solo experience 1000 yen coupon','<span>(The facility is currently not open to the public in July - August 2023)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '2-1-44 Hokukoryokuchi, Konohana-ku, Osaka-shi, Osaka (Maishima Urban Outdoor Base, Osaka City Paragrider）',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.west-east.jp/paraglider/index.html'],},
                { cid: 2, text: ['Reservation site (Japanese only)'],noColor:true},
                { cid: 3, text: ['https://osakamachipara.com/'],},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Reservation only. Please make a reservation in advance. If no reservation is made in advance, the location may be closed. Please check before using the ticket.'] },
                { cid: 2, text: ['Please complete a reservation: reservations can be made through the website ','<br>(open from February 20).'] },
                { cid: 3, text: ['You need to bring clothes/shoes that make you feel free to exercise.'] },
                { cid: 4, text: ['In addition, please refer to the timetable and activity application form for the day of your visit.'] },
                { cid: 5, text: ['Facility restrictions (age: over 3 years old / height: under 190cm / weight: under 80kg)'] },
                ]
            }
          ]
        },
        // 
        {
          id: 19,
          img: require('@/assets/images/hfkansai/en/SUPExperience_en.png'),
          title: ['Strolling on the Water SUP Experience 1000 Yen Coupon','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒554-0042 1-2-25 Hokukoryokuchi, Konohana-ku, Osaka City, Osaka Prefecture',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.west-east.jp/mishima-citysup.html'],},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Round SUP/BIG SUP cannot be experienced by junior high school students (or younger).'] },
                { cid: 2, text: ['There is a height restriction for Bicycle SUP.'] },
                { cid: 3, text: ['Drinkers/pregnant women cannot participate.'] },
                { cid: 4, text: ['It may be canceled due to weather conditions.'] },
                { cid: 5, text: ["Please wear clothes that you don't mind getting wet. (You may get wet due to splashes or watercraft falls.)"] },
                ]
            }
          ]
        },
        // 
        {
          id: 20,
          img: require('@/assets/images/hfkansai/en/KeihanRailwa_en.png'),
          title: ['Keihan Railway KYOTO-OSAKA SIGHTSEEING PASS 1 Day','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [
                { cid: 1, text: ['Keihan Railway【Yodoyabashi Station】'],noColor:true },
                { cid: 2, text: ['3-1-25 Kitahama，Chuo-ku, Osaka (Premium Car ticket window)','<br>'],noColor:true },
                { cid: 3, text: ['Keihan Railway 【Kitahama Station】',],noColor:true },
                { cid: 4, text: ['1-8-16 Kitahama，Chuo-ku, Osaka (Premium Car ticket window)','<br>'],noColor:true },
                { cid: 5, text: ['Keihan Railway【Temmabashi Station】'],noColor:true },
                { cid: 6, text: ['1-1 Temmabashikyomachi,Chuo-ku, Osaka. (Station office)','<br>'],noColor:true },
                { cid: 7, text: ['Keihan Railway【Kyobashi Station】'],noColor:true },
                { cid: 8, text: ['2-1-38 Higashinodamachi, Miyakojima-ku, Osaka (Premium Car ticket window)','<br>'],noColor:true },
                { cid: 9, text: ['Keihan Railway【Sanjo Station】'],noColor:true },
                { cid: 10, text: ['Sanjo Ohashi Bridge, Higashiyama-ku, Kyoto (Premium Car ticket window)'],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.keihan.co.jp/travel/en/trains/passes-for-visitors-to-japan/kyoto-osaka.html'],},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Redeem physical ticket before take the train.'] },
                { cid: 2, text: ['Validity of the physical ticket is printed on the ticket.'] },
                { cid: 3, text: ['Ticket can be used for「Local」「Semi-express」「Sub-express」「Express」「Rapid Express」「Limited Express」「Rapid Limited Express」train.It cannot be used for 「premium car」 (same tram of designated seat).'] },
                { cid: 4, text: ["Guest needs to buy the designated seat of 「premium car」if there’s any need."] },
                ]
            }
          ]
        },
        // 
        {
          id: 21,
          img: require('@/assets/images/hfkansai/en/CarThemePavilion_en.png'),
          title: ['Voucher for the Japanese Initial D Sports Car Theme Pavilion ','(valued at 5,500 yen)','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒551-0031 12-21, Izuo 4-chome, Taisho-ku, Osaka City, Osaka Prefecture',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://mrhiro-jdm.com/']},
                { cid: 2, text: ['Reservation'],noColor:true },
                { cid: 3, text: ['https://mrhiro-jdm.com/rental']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Content includes: ','①Japanese initial D sports car rental discount 2,000 yen ','②professional racing simulator 2,000 yen ','discount for experience with 30 points or more ','③ Electric drift car experience 1,500 yen','discount for experience with 20 points or more'] },
                { cid: 2, text: ['One ticket can be experienced separately by multiple people. For example: adults play racing simulator, children play drift car.'] },
                { cid: 3, text: ['Only valid on the same day.'] },
                { cid: 4, text: ['For initial D sports car rental, it is recommended to make an appointment in advance on the official website.'] },
                { cid: 5, text: ['Please note that there may be cases where it may not be available due to full occupancy.'] },
                { cid: 6, text: ['The sports car rental experience requires a driver’s license. Please confirm the relevant information in advance on the official website.'] },
                ]
            }
          ]
        },
        {
          id: 22,
          img: require('@/assets/images/hfkansai/en/terminal_en.png'),
          title: ['Osaka Restaurant Floor "Ichiroku Gourmet" 1,300 yen meal ticket','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '3-1-3 Umeda, Kita Ward, Osaka 530-0001 Toki no Hiroba "Bar del Sole" on the 5th floor of JR Osaka Station','<span style="color:red;">※"Bar del Sole" will be closed from 7/18 (Tue) to 7/21 (Fri),during this period meal vouchers cannot be redeemed.</span>'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://osakastationcity.com/16gourmet/']},
                { cid: 2, text: ['https://osakastationcity.com/pdf/ichiroku_inbound.pdf']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['We will give you a total of two meal tickets, one for 1,000 yen and one for 300 yen, which can be used at the target store. '] },
                { cid: 2, text: [
                  'Meal vouchers can be used as cash vouchers at the following stores.',
                  '<br>・Ichiroku gourmet on the 16th floor of the South Gate Building「Miyazaki Shabu-Shabu Kiriho(宮崎しゃぶしゃぶ霧峰)」「A Ran(あ・らん)」「Osaka Chikuyotei(大阪竹葉亭)」「Uosa(佐)」「Ginza Hageten(銀座ハゲ天)」「Yomenya Goemon(洋麺屋五右衛門)」「Salon roe and I(サロン卵と私)」「Saint Marc(サンマルク)」「Cafe Eikokuya(カフェ 英國屋)」「Niku no Takumi Nagataya(肉の匠 永田屋)」「Tsuru (弦)」「Hakakuan(八かく庵) 」「PIER 30 GRILL」「Chinese Cuisine Rhodes(中国料理 ロドス)」',
                  '<br>・「Cafe de Clever」 on the 1st floor of the South Gate Building',
                  '<br>・「San Marco」 on the first basement floor of the South Gate Building',
                  '<br>・ Toki no Hiroba (5th floor of Osaka Station) 「Bar del Sole」'
                  ] },
                { cid: 3, text: ['Multiple meal tickets can be used for each transaction.'] },
                { cid: 4, text: ['Meal vouchers cannot be exchanged for cash or given change.'] },
                { cid: 5, text: [' Meal tickets will be collected at the store where they are used.'] },
                { cid: 6, text: ['Meal vouchers cannot be used at Daimaru Umeda, Hotel Granvia Osaka, LUCUA Osaka, Eki Marche Osaka, Osaka Station City Cinema, etc.'] },
                { cid: 7, text: ['The store you can use this ticket may change.'] },
                { cid: 8, text: ['Business hours are subject to change without notice. In addition, no refunds will be given even if you cannot use it due to temporary closure, full occupancy, etc.'] },
                { cid: 9, text: ['Cancellations and refunds cannot be made after redeeming meal tickets.'] },
                { cid: 10, text: ['Cannot be used by presenting the QR code. Please be sure to exchange it for a meal ticket before using each store.'] },
                { cid: 11, text: ['Cannot be used outside of the target stores.'] },
                ]
            }
          ]
        },
        {
          id: 23,
          img: require('@/assets/images/hfkansai/en/meitian_en.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/ezYPmNdS_no',
          title: ['Umeda Sky Building KUCHU TEIEN OBSERVATORY Ticket','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                'Umeda Sky Building, 1-1-88 Oyodonaka, Kita Ward, Osaka 531-6039 (39th floor ticket counter)',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.skybldg.co.jp/en/']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: [" It cannot be used on special days such as the Osaka Naniwa Yodogawa Fireworks Festival (2023/8/5) and New Year's Eve."] },
                { cid: 2, text: [' For guest who use wheelchair please take the high-floor elevator from the first floor of Tower East. '] },
                { cid: 3, text: ['In case of heavy rain, the rooftop floor may be closed.'] },
                ]
            }
          ]
        },
        {
          id: 24,
          img: require('@/assets/images/hfkansai/en/CHOICE_en.png'),
          title: ['BAR CHOICE Choquet 1000 Yen','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                'Yoneyama Building 1F, 1-8-24 Higashi-Shinsaibashi, Chuo-ku, 542-0083',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://choice-cola.com/']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1,000 yen coupon.'] },
                { cid: 2, text: ['Please check the official website for the opening hours in advance.'] },
                { cid: 3, text: ['If there is a price difference, the difference needs to be paid on site.'] },
                { cid: 4, text: ['Please understand that there may be cases that the ticket cannot be used because the store is full.'] },
                ]
            }
          ]
        },
        {
          id: 25,
          img: require('@/assets/images/hfkansai/en/Wonder_en.png'),
          title: ['Dotonbori Grill & Bar Wonder Choquet 1000 Yen','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '1-1-16 Dotonbori, Chuo-ku, Osaka 542-0071 B1 Riverside',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://wonderpub.jp/']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1,000 yen coupon.'] },
                { cid: 2, text: ['Please check the official website for the opening hours in advance.'] },
                { cid: 3, text: ['If there is a price difference, the difference needs to be paid on site.'] },
                { cid: 4, text: ['Please understand that there may be cases that the ticket cannot be used because the store is full.'] },
                { cid: 5, text: ['Contains alcoholic beverages for sale. Purchases are restricted to individuals over the age of 20.'] },
                ]
            }
          ]
        },
        {
          id: 26,
          img: require('@/assets/images/hfkansai/en/chisozanmai_en.png'),
          title: ['Daimaru Matsuzakaya Department store chiso-zanmai Daimaru Umeda Coupon 1000 Yen','<span>(2023/6/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                'Daimaru Osaka-Umeda 14F, 3-1-1 Umeda, Kita-ku, Osaka 530-8202, Japan',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.daimaru.co.jp.e.md.hp.transer.com/umedamise/restaurant/chisozanmai.html']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1,000 yen coupon.(Only applicable for the case of spending over 5,000 yen per person).'] },
                { cid: 2, text: ['Please check the official website for the opening hours in advance.'] },
                { cid: 3, text: ['If there is a price difference, the difference needs to be paid on site.'] },
                { cid: 4, text: ['Please understand that there may be cases that the ticket cannot be used because the store is full.'] },
                ]
            }
          ]
        },
        {
          id: 28,
          img: require('@/assets/images/hfkansai/en/LUCUAosaka_en.jpg'),
          title: ['Osaka Station direct shopping mall "LUCUA osaka" 1,000 yen common product coupon in branch','<span>(2023/6/13~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                'The service center on the 2nd floor of LUCUA 1100 ','<span style="color:red;">※Acceptance until December 31, 2023.</span>',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.lucua.jp/en/']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Content includes:1 coupon for 1000 yen'] },
                { cid: 2, text: ['Expiry date:','Gift certificates redeemed before August 31, 2023 are valid until August 31, 2023.','Gift certificates redeemed after September 1, 2023 will be valid until January 31, 2024.'] },
                { cid: 3, text: ['This coupon cannot be exchanged for cash, nor can it be used to purchase other coupons, stamps, postcards, etc.'] },
                { cid: 4, text: ['This ticket cannot given change.'] },
                { cid: 5, text: [`This coupon cannot be used at part of the stores in LUCUA. (Stores not available: LUCUA 5F "C-pla", LUCUA 9F "FUJIMOTO Ophthalmology", LUCUA 1100 (Ihre) B1F "McDonald's")In addition, the store picture currently displayed is for March 2023, and the store may be changed.`] },
                { cid: 6, text: ['Business hours may be changed without notice. In addition, no refunds will be given even if you cannot use it due to temporary closure, full occupancy, etc.'] },
                ]
            }
          ]
        },
        {
          id: 29,
          img: require('@/assets/images/hfkansai/en/EKI_MARCHE_en.jpg'),
          title: ['1000 yen common coupon for shopping center "Eki Marche Osaka" in Osaka Station','<span>(2023/6/13~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                'The informtation counter in Eki Marche Osaka',
                '<span style="color:red;">※Acceptance until December 31, 2023.</span>',
                '<span style="color:red;">※E-tickets can be exchanged for coupons within 90 days from the date of reservation.</span>',
                '<span style="color:red;">※Cannot be used or exchanged on days when the facility is closed <5/9 (Tue), 9/5 (Tue)></span>',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://osaka.ekimaru.com/en']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Content includes: 2 common coupons for 500 yen'] },
                { cid: 2, text: ['The valid period of printed coupons ends on the last day of the "next month" after the time of the e-ticket redemption. (For example: If it is exchanged into a printed coupon on MAR 15, the valid period of the that coupon ends on APR 30.)'] },
                { cid: 3, text: ['This ticket can be used multiple times per transaction.'] },
                { cid: 4, text: ['This ticket cannot be exchanged for cash or given change.'] },
                { cid: 5, text: [`Business hours may be changed without notice. In addition, no refunds will be given even if you cannot use it due to temporary closure, full occupancy, etc.`] },
                { cid: 6, text: ['Cancellations and refunds cannot be made after redeeming meal tickets.'] },
                { cid: 7, text: ['Cannot be used by presenting the QR code. Please be sure to use each store after exchanging it for a coupon ticket.'] },
                { cid: 8, text: ['Cannot be used outside of the target stores. (Stores not eligible for coupons in Eki Marche: JTB, bank ATM, DreamShop)'] },
                { cid: 9, text: ['A total of 500 sets will be provided (while stocks last only).'] },
                ]
            }
          ]
        },
        {
          id: 31,
          img: require('@/assets/images/hfkansai/en/Yamachan_en.jpg'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/dbJ6ER5g6GM',
          title: ['Osaka Umeda Takoyaki Market, Yamachan HEP FIVE Store 1000 yen discount coupon','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒530-0017 5-15 Kakuda-cho, Kita-ku, Osaka-shi, Osaka HEP Five 1st floor',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1000 yen discount coupon.'] },
                { cid: 2, text: ['Please check the official website in advance for business hours.'] },
                { cid: 3, text: ['If there is a price difference, you will need to pay the balance on-site.'] },
                { cid: 4, text: ['There may be situations where the coupon cannot be used due to the store being full. We apologize for any inconvenience.'] },
                ]
            }
          ]
        },
        {
          id: 32,
          img: require('@/assets/images/hfkansai/en/HEP_NAVIO_en.jpg'),
          title: ['Osaka Umeda Takoyaki Market, Takoyaki juhachiban HEP NAVIO Store 1000 yen discount coupon','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒530-0017 7-10 Kakuda-cho, Kita-ku, Osaka-shi, Osaka HEP Five 1st floor',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1000 yen discount coupon.'] },
                { cid: 2, text: ['Please check the official website in advance for business hours.'] },
                { cid: 3, text: ['If there is a price difference, you will need to pay the balance on-site.'] },
                { cid: 4, text: ['There may be situations where the coupon cannot be used due to the store being full. We apologize for any inconvenience.'] },
                ]
            }
          ]
        },
        {
          id: 33,
          img: require('@/assets/images/hfkansai/en/Yashiki_en.jpg'),
          title: ['Osaka Umeda Takoyaki Market, Kougaryu HEP NAVIO Store 1000 yen discount coupon','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒530-0017 7-10 Kakuda-cho, Kita-ku, Osaka-shi, Osaka HEP Five 1st floor',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1000 yen discount coupon.'] },
                { cid: 2, text: ['Please check the official website in advance for business hours.'] },
                { cid: 3, text: ['If there is a price difference, you will need to pay the balance on-site.'] },
                { cid: 4, text: ['There may be situations where the coupon cannot be used due to the store being full. We apologize for any inconvenience.'] },
                ]
            }
          ]
        },
        {
          id: 34,
          img: require('@/assets/images/hfkansai/en/kukuru_HEP_FIVE_en.jpg'),
          title: ['Osaka Umeda Takoyaki Market kukuru HEP FIVE Store 1000 yen discount coupon','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒530-0017 5-15 Kakuda-cho, Kita-ku, Osaka-shi, Osaka HEP Five 1st floor',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1000 yen discount coupon.'] },
                { cid: 2, text: ['Please check the official website in advance for business hours.'] },
                { cid: 3, text: ['If there is a price difference, you will need to pay the balance on-site.'] },
                { cid: 4, text: ['There may be situations where the coupon cannot be used due to the store being full. We apologize for any inconvenience.'] },
                ]
            }
          ]
        },
        {
          id: 35,
          img: require('@/assets/images/hfkansai/en/HEP_NAVIO_Umeda_en.jpg'),
          title: ['Osaka Umeda Takoyaki Market, Aizu-ya Umeda HEP NAVIO Store 1000 yen discount coupon','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒530-0017 7-10 Kakuda-cho, Kita-ku, Osaka-shi, Osaka HEP Five 1st floor',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1000 yen discount coupon.'] },
                { cid: 2, text: ['Please check the official website in advance for business hours.'] },
                { cid: 3, text: ['If there is a price difference, you will need to pay the balance on-site.'] },
                { cid: 4, text: ['There may be situations where the coupon cannot be used due to the store being full. We apologize for any inconvenience.'] },
                ]
            }
          ]
        },
        {
          id: 36,
          img: require('@/assets/images/hfkansai/en/bistecca_en.png'),
          title: ['bistecca IL FORNO 1000 yen discount coupon','<span>(2023/8/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '1 Chome-5-17 Minamihorie, Nishi Ward, Osaka, 550-0015 Japan',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://bistecca-ilforno.jp/']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1000 yen discount coupon.'] },
                { cid: 2, text: ['Please check the official website in advance for business hours.'] },
                { cid: 3, text: ['If there is a price difference, you will need to pay the balance on-site.'] },
                { cid: 4, text: ['There may be situations where the coupon cannot be used due to the store being full. We apologize for any inconvenience.'] },
                ]
            }
          ]
        },
        {
          id: 37,
          img: require('@/assets/images/hfkansai/en/MIO_EN.jpg'),
          title: ['"Tennouji MIO Mall Common Voucher: 1500 Yuan Discount Coupon"','<span>(2023/9/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '10-39 Heidenaincho, Tennoji-ku, Osaka City, Postal Code: 543-0055"Tennoji MIO Main Building," 2nd Floor Information Counter',
                '※Acceptance until December 30,2023.',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.tennoji-mio.co.jp/lang/en/access/']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['This offer is only valid for stores within Tennouji MIO (Main Building and Plaza Building).'] },
                { cid: 2, text: ["Not available at some stores. Please ask the staff at each store when using this coupon."] },
                { cid: 3, text: ['Please pay the remaining balance of the discount coupon in cash. No change will be given.'] },
                { cid: 4, text: ['Coupons are non-refundable and cannot be redeemed for cash.'] },
                { cid: 5, text: ['We are not responsible for any fire, theft, loss, or destruction of the coupon.'] },
                { cid: 6, text: ['The operating hours of some stores may differ. In case of any changes to the operating hours, we regret to inform you that no separate notice will be given.'] },
                { cid: 7, text: ['Content includes: 3 common coupons for 500 yen'] },
                ]
            }
          ]
        },
        {
          id: 43,
          img: require('@/assets/images/hfkansai/en/EDION_EN.jpg'),
          title: ['EDION Namba sightseeing enjoyment pack 1000 yen coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['EDION Namba main store 8th floor, 3-2-18 Namba, Chuo-ku, Osaka'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://namba.edion.com/en/'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Enjoy the 1000 yen coupon.'] },
                { cid: 2, text: [
                  'Pack includes:',
                  '<br>・Ninja escape game experience (equivalent to 1,000 yen)',
                  '<br>・Free luggage storage for 1 day (equivalent to 800 yen)',
                  '<br>・Use of the powder room for 1 hour (equivalent to 300 yen) ※You can use the latest home appliances and cosmetics.',
                  '<br>・30 minute use of charging spot (equivalent to 200 yen) ※Can be used for both iPhone and Android.'
                ] },
                { cid: 3, text: ['Please check the official website in advance for business hours.'] },
                { cid: 4, text: ['Any difference in price must be paid on-site.'] },
                { cid: 5, text: ['It may be full and you may not be able to use it. Please note.'] },
              ]
            }
          ]
        },
        {
          id: 44,
          img: require('@/assets/images/hfkansai/en/rentcar_en.png'),
          title: ['Station car rental 2000 yen coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '(1) Station Car Rental Shin-Osaka: ',
                '5-16-1 Nishinakajima, Yodogawa-ku, Osaka City, JR Shin-Osaka Station (South Exit, 1st floor)',
                '(2) Station Car Rental Kyoto:',
                '940 Higashisanno-cho, Shimogyo-ku, Kyoto City, JR Kyoto Station (1st floor of the station building parking lot)',
                '(3) Station Car Rental Shin-Kobe: ',
                '1-3-1 Kanachō, Chuo-ku, Kobe City, JR Shin-Kobe Station (1st floor central hall)',
                '(4) Station Car Rental Himeji: ',
                '125 Minamiekimae-cho, Himeji City, Hyogo Prefecture, JR Himeji Station (turn left at the central ticket gate, exit to the south, within the station front parking lot)',
                ] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['(1) Station Car Rental: Shin-Osaka Branch'],noColor:true },
                { cid: 2, text: ['https://www.ekiren.com/eigyosyo?eigyou=270200'] },
                { cid: 3, text: ['(2) Station Car Rental: Kyoto Branch '],noColor:true },
                { cid: 4, text: ['https://www.ekiren.com/eigyosyo?eigyou=260400'] },
                { cid: 5, text: ['(3) Station Car Rental: Shin-Kobe Branch'],noColor:true },
                { cid: 6, text: ['https://www.ekiren.com/eigyosyo?eigyou=290200'] },
                { cid: 7, text: ['(4) Station Car Rental: Himeji Branch'],noColor:true },
                { cid: 8, text: ['https://www.ekiren.com/eigyosyo?eigyou=290800'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 2000 yen coupon.'] },
                { cid: 2, text: ['Limited for same-day use, reservations are not accepted.'] },
                { cid: 3, text: ["Instructions: Please present the Kansai Pass QR code, driver's license, and passport (for non-Japanese nationals) when applying for a rental car at eligible stores."] },
                { cid: 4, text: ['Please check the official website in advance for operating hours.'] },
                { cid: 5, text: ['Any price difference must be paid on-site.'] },
                { cid: 6, text: ['Car model preferences are not available.'] },
                { cid: 7, text: ['Please be aware that it may become fully occupied and unavailable for use. We apologize for any inconvenience.'] },
                { cid: 8, text: ['When driving in Japan with a foreign driving license, you must hold a valid "International Driving License" or "Foreign Driving License + Translate Version in Japanese". Please confirm before coming to the store.'] },
                { cid: 9, text: ['We provide customers with cars filled with gasoline when picking up the car. Please return the car with the same filling of gasoline when returning the car. If you return the car without filling up the gas, you will be charged more than the market price for gas. (Charge is based on the actual distance traveled by the car.)'] },
                { cid: 10, text: ['The use of this car rental service will be based on Japanese domestic laws and Japanese car rental terms.'] },
              ]
            }
          ]
        },
        {
          id: 45,
          img: require('@/assets/images/hfkansai/en/mingShi_en.png'),
          title: ['The Akashi Sushi 2,000 Yen couponThe Akashi Sushi 2,000 Yen coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['〒673-0891 Hyogo, Akashi, Oakashicho, 1-chome−２３'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.yokoso-akashi.jp/coupon.html'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Expenses includes: A discount coupon for 2000 Yen, redeemable for one serving of Akashi Sushi menu (one person).'] },
                { cid: 2, text: ['Please check the official website in advance to confirm the operating hours.'] },
                { cid: 3, text: ["Due to the availability of ingredients and seasonal changes, the contents of the menu may change without prior notice. We appreciate your understanding."] },
              ]
            }
          ]
        },
        {
          id: 38,
          img: require('@/assets/images/hfkansai/en/ADVENTURE_WORLD_EN.jpg'),
          title: ['Animal Play Zone Adventure World - 1-day admission ticket 1000-yen coupon','<span>(2023/9/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒649-2201, 2399 Katada, Shirahama-cho, Nishimuro-gun, Wakayama Prefecture (Ticketing Window)',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.aws-s.com/en/']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1,000-yen price deduction (admission ticket only).'] },
                { cid: 2, text: ["Please check the official website for the facilities' business hours in advance."] },
                { cid: 3, text: ['If there is a price difference, the difference needs to be paid on-site.'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfkansai/en/グループ 255.jpg'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/N-MxbCGVfIM',
          title: ['Kyoto Railway Museum','<span>(2023/8/18~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['Kankijicho,Shimogyo Ward,Kyoto,Japan(Museum entrance)'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://www.kyotorailwaymuseum.jp/en/'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please present the admission ticket to staff', 'when guest re-enter the museum.'] },
                { cid: 2, text: ['Please check the official website in advance for business hours.'] },
              ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfkansai/en/グループ 225.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/3vEpVmem5dU',
          title: ['Arashiyama Gourmet Coupon +', 'Randen 1 Day Free Ticket'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['20-2 Sagatenryuji Tsukurimichicho,', 'Ukyo ku, Kyoto'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://www.keifuku.co.jp/cms/wp-content/uploads/cc0d12d1bb04c0b9865d0595a5d770a9-768x975.jpg'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the train timetable on the', 'Official Website.'] },
                { cid: 2, text: ['Please check the Official Website for the stores', ' that are eligible for the food coupon.'] },
                { cid: 3, text: ['The remaining food coupons cannot be', 'redeemed for cash.'] },
              ]
            }
          ]
        },
        // 
        {
          id: 10,
          img: require('@/assets/images/hfkansai/sake_EN.jpg'),
          title: ['Japanese SAKE Festival in KYOTO ', '「Sake Spring 」2023 Summer Festival Drinking Ticket'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['9-1 Okazaki Seishoji-cho, Sakyo-ku, Kyoto 〒606-8343','(Kyoto City Kangyokan Miyako Messe 3F 3rd Exhibition Hall)'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://www.sakesp.com/2023summer'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Event Period: 2023/7/8~2023/7/9 '] },
                { cid: 2, text: ['This is a wine tasting event. Guests under the age of 20 are not allowed to participate.'] },
                { cid: 3, text: ['Once you leave, re-entry is not permitted.'] },
                { cid: 4, text: ['Please check the official website in advance to confirm the operating hours.'] },
              ]
            }
          ]
        },
        {
          id: 39,
          img: require('@/assets/images/hfkansai/en/TOEI_EN.jpg'),
          title: ['TOEI Kyoto Studio Park Admission'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['10 Uzumasa Higashihachiokacho, Ukyo-ku, Kyoto-shi'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://global.toei-eigamura.com/'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website in advance for business hours. '] },
                { cid: 2, text: ['There are restrictions on the use of attractions, so please check the official website in advance.'] },
              ]
            }
          ]
        },
        {
          id: 40,
          img: require('@/assets/images/hfkansai/en/pph_EN.jpg'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/nvht8Y_bbYk',
          title: ['Lake Biwa Otsu Prince Hotel common ticket 1,500 yen worth'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['4-7-7, Nionohama, Otsu City Shiga'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://www.princehotels.com/otsu/'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['The expiration date for the common ticket is one week from the date of issue. In-house common tickets whose expiration date has passed are invalid.'] },
                { cid: 2, text: ['This voucher cannot be used for accommodation expenses.'] },
                { cid: 3, text: ['Please pay the difference in cash. Change cannot be given.'] },
                { cid: 4, text: ['Business hours are subject to change without notice. There will also be no refunds if you are unable to use the service due to temporary closures, full seats, etc.'] },
              ]
            }
          ]
        },
        {
          id: 41,
          img: require('@/assets/images/hfkansai/en/PASSPORT_EN.jpg'),
          title: ['NAGAHAMA ODEKAKE PASSPORT (2 days)'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['1-5 Kitafunecho, Nagahama City (inside JR Nagahama Station)'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://kitabiwako.jp/en/travel_guide'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Passports are valid for 2 days (calendar days) including the first day of use. ※Not 48 hours'] },
                { cid: 2, text: ['Only one admission per facility. You may not enter the same facility multiple times.'] },
                { cid: 3, text: ['Please check the business hours and holidays of facilities and stores before using.'] },
                { cid: 4, text: ['Additional fees may be required for special exhibitions at each facility.'] },
              ]
            }
          ]
        },
        {
          id: 42,
          img: require('@/assets/images/hfkansai/en/yxt_EN.jpg'),
          title: ['Issindo 1000 yen coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['3-9 Kamisanjocho, Nara City, Nara Prefecture'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://issindo-nara.jp/'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Enjoy the 1000 yen coupon.'] },
                { cid: 2, text: ['Please check the official website in advance for business hours.'] },
                { cid: 3, text: ['If there is a difference in price, you will need to pay it on-site.'] },
              ]
            }
          ]
        },
        {
          id: 30,
          img: require('@/assets/images/hfkansai/en/Yoshitaya_en.jpg'),
          title: ['Yoshitakaya Kamejirushi Skin Care Soap Redemption Coupon','<span>(2023/6/13~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '259 Arimacho, Kita Ward, Kobe City, Hyogo Prefecture 651-1401',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.yoshitakaya.com/']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['The product images shown are for illustration purposes only and may not be an exact representation of the product.'] },
                { cid: 2, text: ['Please check the official website for the opening hours in advance.'] },
                ]
            }
          ]
        },
        {
          id: 27,
          img: require('@/assets/images/hfkansai/en/carbonated_en.jpg'),
          title: ['Ganso Mitsumori Honpo Carbonated rice cracker Canned 30 pieces','<span>(2023/6/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '290-1 Arimacho, Kita Ward, Kobe City, Hyogo Prefecture 651-1401',],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['http://tansan.co.jp/']},
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['In the case that the store is crowded, you will have to wait with general customers. This ticket has no priority.'] },
                { cid: 2, text: ['The product images shown are for illustration purposes only and may not be an exact representation of the product.'] },
                { cid: 3, text: ['Please check the official website for the opening hours in advance.'] },
                ]
            }
          ]
        },
        // 
        {
          id: 11,
          img: require('@/assets/images/hfkansai/en/グループ 226.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/q101QK5r8Sc',
          title: ['Ropeway Round-trip &', 'Herb Gardens Admission'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['1-4-3 Kitanochō,Chuo Ward,Kobe,Hyogo,Japan', '(4F Information Center of Sanroku Station)'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://www.kobeherb.com/en/'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['During night-time opening (after 5 p.m.),', 'only the top observatory area is open.'] },
                { cid: 2, text: ['Open all year round without 1-month', 'maintenance works in the winter.'] },
                { cid: 3, text: ['It might be closed due to bad weather such', 'as strong wind and/or thunder.'] },
              ]
            }
          ]
        },
        {
          id: 12,
          img: require('@/assets/images/hfkansai/en/グループ 227.png'),
          title: ['Kobe Concerto Coupon JPY1000'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['1F,Kobe Harborland umie,1-6-1 Higashikawasakicho,', 'Chuo,Kobe,Japan(Concerto boarding area)'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://thekobecruise.com/concerto/'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the operation schedule on the', 'Official Website.'] },
                { cid: 2, text: ['You may not be able to board the boat if it is full.'] },
                { cid: 3, text: ['The difference in fees needs to be paid on site.'] },
              ]
            }
          ]
        },
        {
          id: 13,
          img: require('@/assets/images/hfkansai/en/グループ 228.png'),
          title: ['Mosaic Big Ferris Wheel'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['Kobe Harborland umie,1-6-1 Higashikawasakicho,', 'Chuo,Kobe,Japan(Mosaic)'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://umie.jp/features/mosaickanransya'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: false, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Subject to change due to the scheduled facility', 'maintenance or weather condition'] },
              ]
            }
          ]
        },
        {
          id: 14,
          img: require('@/assets/images/hfkansai/en/グループ 232.png'),
          title: ['Kannonya Coupon JPY1000'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['1F,Kobe Harborland umie,1-6-1 ', 'Higashikawasakicho,Chuo,Kobe,Japan(Mosaic)'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://umie.jp/shops/detail/76'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1,000 yen coupon can be used.'] },
                { cid: 2, text: [' If there is a price difference, the difference ', 'needs to be paid on site.'] },
                { cid: 3, text: ['Please understand that there may be cases that the ticket cannot be used because the store is full.'] }
              ]
            }
          ]
        },
        {
          id: 15,
          img: require('@/assets/images/hfkansai/en/グループ 246.png'),
          title: ['Tonton Tonkatsu JPY1000 / Set Menu'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['2F,Kobe Harborland umie,1-6-1 Higashikawasakicho,', 'Chuo,Kobe,Japan(Mosaic)'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://umie.jp/shops/detail/93'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1,000 yen coupon/set.'] },
                { cid: 2, text: ['If there is a price difference, the difference', 'needs to be paid on site.'] },
                { cid: 3, text: ['Please understand that there may be cases that the ticket cannot be used because the store is full.'] }
              ]
            }
          ]
        },
        {
          id: 16,
          img: require('@/assets/images/hfkansai/en/グループ 233.png'),
          title: ['Frantz Sweets Coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: ['2F,Kobe Harborland umie,1-6-1 Higashikawasakicho,', 'Chuo,Kobe,Japan(Mosaic)'] }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [{ cid: 1, text: ['https://umie.jp/shops/detail/98'] }]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: false, //是否启用ul的数字前缀 //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can choose 1 of the 3 desserts.'] },
              ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFKansai&l=en')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-kansai-1-week-free-pass/en', '_self')
  }
}
</script>
<style lang="scss" scoped>
.xiao_btn_img{
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin-top: 16px;
  a{
    height: 32px;
    width: 32%;
    // flex: 1;
    // text-align: center;
  }
  img{
    // flex: 1;
    // width: auto;
    width: 90%;
    max-width: 144px;
    height: auto;
    // height: 100%;
  }
}
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
.dltAbq:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>